///// Server urls

//localhost
//export const SERVER_BASE_URL = 'http://localhost:8000';

//STG Server
export const SERVER_BASE_URL = 'https://api.grindon-tandoori.digitafact.com';

//Main Domain


export const API_BASE_URL = `${SERVER_BASE_URL}/api`;

export const PRE_AUTH_ENDPOINT = `${SERVER_BASE_URL}/sanctum/csrf-cookie`;
export const AUTH_ENDPOINT_LOGIN = `${SERVER_BASE_URL}/login`;
export const AUTH_ENDPOINT_LOGOUT = `${SERVER_BASE_URL}/logout`;
export const AUTH_ENDPOINT_REGISTER = `${SERVER_BASE_URL}/register`;
export const AUTH_USER_ENDPOINT = `${API_BASE_URL}/user`;
export const AUTH_ENDPOINT_FORGET_PASSWORD = `${SERVER_BASE_URL}/password/email`;
export const AUTH_ENDPOINT_RESET_PASSWORD = `${SERVER_BASE_URL}/password/reset`;

export const API_GET_OPENING = '/opening';
export const API_GET_SETTING = '/setting';
export const API_GET_SEO = '/get-meta';

export const API_STORE_ORDER = '/order';

export const GET_TODAYS_ORDER = `${API_BASE_URL}/order`;

export const CURRENCY_SYMBOL = '£';

export const USER_ADDRESS_TYPES = ['home', 'work'];

export const USER_ADDRESS = `${AUTH_USER_ENDPOINT}/address`;

export const API_GET_ORDER_TOKEN = '/order/get-token';

export const API_ADD_RESERVATON = '/reservation';

export const API_USER_FROFILE_UPDATE = 'user/update';

export const API_STATISTICAL_COUNT = '/statistics';

export const API_DELIVERY_CHARGE = '/order/get-distance';

export const API_USER_POINTS = '/order/get-points';

export const API_COUPON_APPLY = '/order/get-coupon';

export const API_DISCOUNT_APPLY = '/order/get-discount';

export const API_CONTACT_SUBMIT = '/contact-mail';
export const API_GET_OFFERS = '/get-offers';


//card implementation credentials test type
export const PAYMENT_API_FORM_DEV_URL = 'https://web.e.test.connect.paymentsense.cloud/assets/js/client.js';

//card implementation credentials production type
export const PAYMENT_API_FORM_PROD_URL = 'https://web.e.connect.paymentsense.cloud/assets/js/client.js';

//end of card implementation credentials

export const SETTING_FILE_PATH = `${SERVER_BASE_URL}/storage/settings/`;

export const APP_TIMEZONE = 'Europe/London';

export const STRIPE_PAYMENT_KEY_DEV = 'pk_test_51J8oqCHrmIWcrDbhPALY8w6Qq0yQQwB7q1b045ykHor80BgVqqr9WDNvNe4SgB3TTuSB4rpZplaVZnkwzlNAh8Tj00S5sK7DoR';

export const STRIPE_PAYMENT_KEY_PROD = 'pk_live_51J8oqCHrmIWcrDbhXqruU2M88jqhDkU1jOS8B6pCzwOSIyhBHC880mJN29qODJDlPi25HR9D9R6MQr23ceoCzOI600ySpBhSFX';
